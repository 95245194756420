@use "src/scss/config" as *;

.action-bar {
    display: flex;
    justify-content: space-between;
    background: var(--bg-navbar);
    color: var(--nav-links);
    min-height: 40px;
    border-radius: 4px 4px 0 0;
    padding: 0 1.5rem;

    &__title {
        align-self: center;
    }

    &--dark {
        background-color: var(--bg-nav--console);
    }
}
