@use "src/scss/config" as *;

.json-editor {
	pre {
		background-color: var(--bg-json) !important;
		color: inherit !important;
	}

	.hljs-attr {
		color: #59a5d8 !important;
	}

	span {
		font-family: "Roboto Mono", Monaco, monospace !important;
		font-size: 1.4rem !important;
		white-space: pre-line;

		&[style="color: rgb(136, 0, 0);"] {
			color: #FA7921 !important;
		}
	}

	code {
		background: none !important;
	}

}