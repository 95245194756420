@use "src/scss/config" as *;

.accessibleImage {
    &--standard {
        display: block;

        @include dark-mode {
            display: none;
        }
    }

    &--contrast {
        display: none;

        @include dark-mode {
            display: block;
        }
    }
}