.table {
    border-collapse: collapse;
    box-shadow: var(--shadow-nav);
    //min-width: 900px;
    width: 100%;

    tr,
    td {
        padding: 1rem 2rem;
    }

    &--bordered {
        border: 1px solid var(--bg-head-light);
    }

    &--force-break {
        td {
            word-break: break-all;
        }
    }

    &__container {
        width: 100%;
        overflow: hidden;
        overflow-x: auto;
    }

    &__head {
        color: var(--font-head);
        font-size: 1.6rem;
        clear: both;

        tr {
            background: var(--bg-head);
        }

        th {
            padding: 1rem 2rem;
            text-align: left;
            font-weight: 200;

            &:first-child {
                border-radius: 10px 0 0 0;
            }

            &:last-child {
                border-radius: 0 10px 0 0;
            }
        }
    }

    &--light &__head {
        color: var(--headings);

        tr {
            background: var(--bg-head-light);
        }
    }


    &__body {
        font-size: 1.4rem;
        background: var(--bg-container);
        padding-bottom: 0.5rem;
        color: var(--paragraphs);

        tr {
            border-bottom: 1px solid var(--bg-head-light);

            td {
                height: 60px;
            }
        }

        tr:last-child {
            td {
                padding-bottom: 2rem;
                border-bottom: none;
            }

            td:last-child {
                border-radius: 0 0 10px;
            }

            td:first-child {
                border-radius: 0 0 0 10px;
            }
        }
    }

    &__id {
        max-width: 180px;
    }

    &__image {
        em {
            color: var(--headings);
            font-style: italic;
            font-weight: 200;
        }
    }

    &__name {
        max-width: 25rem;

        a {
            font-weight: 500;
            color: var(--headings);
            margin: 0.5rem 0;
            display: block;

            &:hover {
                color: var(--ui-accent);
            }
        }
    }

    &__instances {
        color: var(--ui-accent);

        svg,
        span {
            vertical-align: middle;
        }

        span {
            margin: auto 0.5em;
        }
    }

    &__actions {
        max-width: 8em;

        .btn-circle {
            margin-right: 20px;
        }
    }
}
