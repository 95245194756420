@use "src/scss/config" as *;

.hub {
    position: relative;

    &__table {
        font-size: 1.5em;
        line-height: 1.5em;
        margin-top: 1em;
        padding: 0;

        td {
            svg {
                vertical-align: middle;
            }
        }
    }

    &__container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
}
