@use "src/scss/config" as *;

.space {
    &__subtitle {
        display: inline;
    }

    &__above-box {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin: 2rem 0;

        @include media(">lg") {
            margin: 2rem 0;
        }
    }

    &__buttons {
        @include media(">lg") {
            justify-content: end;
        }

        .attention-animation {
            @keyframes pulse {
                0% {
                    transform: scale(0);
                    opacity: 0;
                }

                20% {
                    opacity: 0.4;
                }

                50% {
                    transform: scale(1);
                    opacity: 0.6;
                }

                80% {
                    opacity: 0.4;
                }

                100% {
                    transform: scale(0);
                    opacity: 0;
                }
            }

            width: 70px;
            height: 70px;
            border-radius: 50%;
            background-color: var(--secondary-pressed);
            position: absolute;
            transform-origin: center center;
            animation: pulse 4s infinite;
            z-index: 2;
            margin-left: 4.6em;
            top: 17.8em;
        }
    }

    .plan-info {
        margin: 4rem 0 2rem;

        @include media(">lg") {
            margin: 0;
        }
    }
}
