@use "src/scss/config" as *;

.label {
    border-radius: 10px;
    position: absolute;
    padding: 0.5rem 1rem;

    // fonts
    &--small {
        font-size: 1.4rem;
    }

    &--big {
        font-size: 1.6rem;
    }

    // colors
    &--blue {
        background-color: var(--tag-background);
        color: var(--tag-text);
    }

    &--yellow {
        background-color: var(--tag-background-yellow);
        color: var(--tag-text);
    }

    // positions y
    &--t {

        &-left,
        &-center,
        &-right {
            top: 0;
        }

        &-left {
            left: 1.2rem;
            transform: translateY(-50%);
        }

        &-center {
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &-right {
            right: 1.2rem;
            transform: translateY(-50%);
        }
    }

    &--b {

        &-left,
        &-center,
        &-right {
            bottom: 0;
        }

        &-left {
            left: 1.2rem;
            transform: translateY(50%);
        }

        &-center {
            left: 50%;
            transform: translate(-50%, 50%);
        }

        &-right {
            right: 1.2rem;
            transform: translateY(50%);
        }
    }
}
