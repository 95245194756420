@use "sass:selector";

@mixin dark-mode {
  .dark & {
    @content;
  }
}

@mixin unify-parent($child) {
  @at-root #{selector.unify(&, $child)} {
    @content;
  }
}
