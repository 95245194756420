@use "src/scss/config" as *;

.quote {
    font-size: $font-xs;
    margin-left: 0;
    padding: 0.5em;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    border: 1px solid var(--confirm);
    background: var(--bg-container);

    @include media(">md") {
        flex-direction: row;
    }

    &__icon {
        color: var(--confirm);
        font-size: 2rem;
        line-height: 0;

        @include media(">md") {
            width: 50px;
            padding-right: 10px;
        }
    }

    &__text {
        padding-right: 2rem;
        color: var(--quote-text);
    }

    &__btn {
        display: block;
        margin-left: auto;
    }

    &--wide {
        width: 100%;
        padding: 1rem 2rem;
    }

    &--wide &__text {
        width: 80%;
    }

    &--narrow {
        max-width: 720px;
    }

    &--narrow &__text {
        width: 100%;
    }

    &--info {
        border: 1px solid transparent;
        background: var(--quote-info)
    }

    &--info &__icon {
        color: var(--quote-icon);
    }

    &--warning {
        border: 1px solid var(--warning);
    }

    &--warning &__icon {
        color: var(--warning);
    }

    &--error {
        border: 1px solid var(--error);
    }

    &--error &__icon {
        color: var(--error);
    }
}
