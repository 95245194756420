.tag {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;

    &__item {
        border: 1px solid black;
        padding: 0.5rem 0.7rem;
        border-radius: 0.7rem;
        font-size: 1.4rem;
    }
}
