@use "src/scss/config" as *;

.tooltip {
    padding: 8px 15px;
    border: none;
    border-radius: 10px;
    font-size: 14px;
    font-family: $font-default;

    &.tooltip--info {
        background-color: var(--tooltip-info-bg);
        color: var(--tooltip-info-text);
    }

    &.tooltip--onboarding {
        background-color: var(--tooltip-onboarding-bg);
        color: var(--tooltip-onboarding-text);
    }

    &__title {
        font-weight: 600;
    }
}

.tooltip-triangle {
    &.tooltip--info {
        border-color: var(--tooltip-info-bg);
    }

    &.tooltip--onboarding {
        border-color: var(--tooltip-onboarding-bg);
    }
}
