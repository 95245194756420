@use "src/scss/config" as *;

.skip-nav {
    z-index: $z-nav;
    width: 220px;
    position: relative;
    align-self: end;

    @include media(">md") {
        align-self: auto;
    }

    &__link {
        display: block;
        position: absolute;
        top: 50%;
        left: 10%;
        background: var(--secondary);
        color: var(--btn-secondary-text);
        font-size: 1.6rem;
        font-weight: 600;
        line-height: 1;
        padding: 0.75rem 1.5rem;
        border: 1px solid var(--ui-base);
        border-radius: 1rem;
        opacity: 0;
        z-index: $z-hidden;
        transform: translateY(-50%);
        text-decoration: none;
        &:focus {
            opacity: 1;
        }
    }
}
