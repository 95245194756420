@use "src/scss/config" as *;

.breadcrumbs {
  display: flex;

  &__crumb {
    color: var(--subtitles);
    font-size: $font-s;

    &:last-child {
      font-weight: 600;
      text-decoration: none;
    }
  }

  &__chevron {
    margin: 0.3em 0.5em -0.3em 0.5em;
    color: var(--subtitles);
    font-size: 1.5em;
  }
}