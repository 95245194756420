@use "src/scss/config" as *;

.account-setup-progress {
    display: flex;
    margin: auto;
    justify-content: center;
    max-width: 80%;
    margin-top: 4rem;
    margin-bottom: 4rem;

    &__name {
        width: 12rem;
        text-align: center;
        
        &__inactive {
            color: var(--icon-disabled);
        }

    }

    &__element {
        display: flex;

        &__1 {
            margin-left: 5rem;
            margin-bottom: 2rem
        }

        &__2 {
            margin-bottom: 2rem
        }

        &__3 {
            margin-right: 5rem;
            margin-bottom: 2rem
        }
    }

    &__filled {
        fill: var(--success);
    }

    &__active {
        fill: var(--success);
    }

    &__inactive {
        fill: var(--icon-disabled);
    }

    &__line {
        flex: 1;
        height: 2px;
       
        align-self: center;
        margin: 0;

        &__filled {
            background-color: var(--success);
        }
        
        &__active {
            background: var(--icon-disabled);
        }

        &__inactive {
            background: var(--icon-disabled);
        }
    }

    &__line-b {
        &__active {
            background-color: var(--success);
        }

        &__inactive {
            background: var(--icon-disabled);
        }

        &__filled {
            background: var(--success);
        }
    }
}
