@use "src/scss/config" as *;

.radio {
    &__group {
        border: none;
        padding: 0;
        margin: 2rem 0;
    }

    &__item {
        display: flex;
        position: relative;
        align-items: center;
        font-size: 1.6rem;
        padding-top: 1rem;

        .attention-animation {
            @keyframes pulse {
                0% {
                    transform: scale(0.5);
                    opacity: 0;
                }
        
                20% {
                    opacity: 0.6;
                }
        
                50% {
                    transform: scale(1);
                    opacity: 0.4;
                }
        
                80% {
                    opacity: 0.6;
                }
        
                100% {
                    transform: scale(0.5);
                    opacity: 0;
                }
            }
        
            width: 46px;
            height: 46px;
            border-radius: 50%;
            background-color: var(--ui-accent);
            position: absolute;
            transform-origin: center center;
            animation: pulse 4s infinite;
            z-index: 0;
            left: -0.83em;
        }
    }

    &__input {
        position: absolute;
        left: 0;
        width: 1px;
        height: 40px;
        line-height: 40px;
        clip: rect(1px, 1px, 1px, 1px);
        margin: 0 0 0 0%;
        padding: 0 0 0 0%;
        border: 0;
        overflow: hidden;
        transition: all .5s ease-in-out;
    }

    &__label {
        cursor: pointer;
        display: flex;
        align-items: center;
        position: relative;
        transition: background .8s;

        &::before {
            content: '';
            width: 2rem;
            height: 2rem;
            border-radius: 100%;
            top: 0rem;
            left: 0rem;
            margin-right: 1rem;
            justify-self: center;
            background: var(--radio-background);
            transition: .4s;
            box-shadow: inset 0 0 0 0.2rem var(--radio-inactive-default), inset 0 0 0rem 0.5rem transparent, 0 0 0rem 0.5rem rgba(34, 34, 34, 0);
        }
    }

    &__input:checked+&__label::before {
        transition: 0s;
        background-color: var(--radio-active-default);
        box-shadow: inset 0 0 0 0.2rem var(--radio-active-default), inset 0 0 0rem 0.5rem var(--radio-background), 0 0 0rem 0 var(--radio-active-hover);
    }
}
