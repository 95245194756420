.dark {
    /** *********** COLORS *********** **/

    // Backgrounds
    --bg-default: #121212;
    --bg-container: #2d2d2d;
    --bg-footer: #3c3d3e;
    --bg-modal: #2d2d2d;
    --bg-panel: #3f3e3e;
    --bg-head: #292929;
    --bg-head-light: #3f3e3e;
    --bg-indicators: #292929;
    --bg-json: #484848;
    --bg-command: #484848;
    --bg-console: #3a3a3a;
    --bg-border: #6e6e6e;
    --bg-nav--console: #292929;
    --bg-navbar: #1a1a1a;
    --bg-nav-link: #707070;
    --bg-drag-drop: #494949;
    --bg-icon: #494949;
    --bg-btn-circle-default: #3f3e3e;
    --bg-btn-circle-hover: #3f3e3e;
    --bg-btn-circle-pressed: #121212;
    --bg-btn-circle-disabled: #121212;
    --bg-banner: #2d2d2d;
    --bg-selected: #707070;

    // Shadows
    --shadow-nav: rgba(0, 0, 0, 0.15);
    --shadow-box: #000;
    --shadow-bg: #3c3d3e;

    // Borders
    --border-light: #000;
    --border-dark: #6e6e6e;
    --border-btn: #F5A966;
    --border-btn-primary-hovered: #F29849;
    --border-btn-hovered: #2F2F2F;
    --border-btn-pressed: #F5A966;
    --border-btn-disabled: #A3A3A3;
    --border-drag-drop: #f5a966;

    // UI colors
    --ui-base: #2d2d2d;
    --ui-accent: #f5a966;
    --ui-accent-hovered: #f29849;

    // Buttons
    --primary: #f5a966;
    --primary-pressed: #f29849;
    --primary-hovered: transparent;

    --secondary: transparent;
    --secondary-pressed: #3f3022;
    --secondary-hovered: #F5A966;

    --tertiary: #54d1db;
    --tertiary-pressed: #87eaf2;
    --tertiary-hovered: #bef8fd;

    --danger: #ed8598;
    --danger-pressed: #f38095;
    --danger-hovered: #f16781;

    --warning: #F5943E;
    --warning-pressed: #cf782c;
    --warning-hovered: #f5a661;

    --confirm: #54d1db;
    --confirm-pressed: #10afbb;
    --confirm-hovered: #47b7c0;

    --error: #f29b9b;
    --success: #10afbb;

    --link: #ffffff;

    // Typography
    --headings: #fbfbfb;
    --subtitles: #fbfbfb;
    --paragraphs: #fbfbfb;
    --font-head: #fbfbfb;
    --title-head: #ffffff;
    --link-label: #b3b3b3;
    --json-default: #e3e3e3;

    --nav-links: #fbfbfb;
    --nav-background-hovered: #707070;
    --nav-background: #707070;
    --nav-links-active: #fbfbfb;
    --nav-links-hovered: #F5A966;
    --main-nav-link-hovered: #000000;

    --btn-primary-text: #121212;
    --btn-secondary-text: #F5A966;
    --btn-secondary-text-hovered: #222121;
    --btn-secondary-text-pressed: #F5A966;
    --btn-confirm-text: #121212;
    --btn-danger-text: #121212;
    --btn-disabled-text: #151515;
    --bg-btn-disabled: #A3A3A3;

    // Navigation
    --top-bar: #3f3e3e;
    --tab-nav-text: #121212;
    --tab-nav-color: #F5A966;

    // Icons
    --icon-primary: #f0f0f0;
    --icon-secondary: #f5a966;
    --icon-json: #f5a966;
    --icon-hover: #f07e19;
    --icon-disabled: #494949;
    --icon-seq: #99d1ff;
    --icon-inst: #f5a966;

    // Logs
    --logs: #6ba4dd;
    --logs-info: #fff3b0;
    --logs-deubg: #d1a0ea;
    --logs-error: #e68282;

    // Radio button
    --radio-background: #3f3e3e;
    --radio-active-default: #f29849;
    --radio-active-hover: #f29849; // shadow opasity 10%
    --radio-active-disabled: #f29849; // opacity of color 50%
    --radio-inactive-default: #ffffff; // opacity of color 60%
    --radio-inactive-hover: #ffffff; //opacity of color 60%, shadow opacity 10%
    --radio-inactive-disabled: #ffffff; // opracity of color 30%

    // Input
    --input-default: #e3e3e3;
    --input-active: #f29849;
    --input-disabled: #3f3e3e;
    --input-error: #ed8598;

    // Checkbox
    --checkbox-default: #ffffff; // opacity 30%
    --checkbox-hovered: #ffffff; // opacity 40%, shadow 10%
    --checkbox-tapped: #f29849;
    --custom-checkbox-border: #3f3e3e;

    // Toast
    --toast-danger: #ed8598;
    --toast-success: #54d1db;
    --toast-text: #121212;

    // Tooltip
    --tooltip-info-bg: #3c3d3e;
    --tooltip-info-text: #fff;
    --tooltip-onboarding-bg: #1770c9;
    --tooltip-onboarding-text: #fff;

    // Tag
    --tag-background: #BEF8FD;
    --tag-background-yellow: #F4D78A;
    --tag-text: #121212;

    // Quote
    --quote-info: #121212;
    --quote-text: #fff;
    --quote-icon: #F4DFA5;

    // Table
    --table-background: #3A3A3A;

    //Select
    --select-border: #DEDEDE;
    --select-shadow: #121212;

    // Menu Button
    --menu-button-border: #dbdbdb;
    --menu-button-background: #2d2d2d;
    --menu-button-background-hover: #494949;
    --menu-button-text: #e3e3e3;
    --menu-button-text-hover: #e7e7e7;
    --menu-button-icon: #e3e3e3;
    --menu-button-icon-hover: #e7e7e7;
    --menu-button-danger: #ed8598;
    --menu-button-danger-hovered: #f16781;

    // Banner
    --banner-gradient: linear-gradient(90deg, #2d2d2d 0%, #121212 100%);
    --banner-title: #99d1ff;
}