@use "src/scss/config" as *;

$avatar-size: 30px;

.top-panel-error {
    display: flex;
}

.user-avatar-error {
    display: flex;
    align-items: center;
    background: transparent;
    border: none;
    flex: 0 0 $avatar-size;
    min-height: $avatar-size;
    height: $avatar-size;
    max-height: $avatar-size;
    color: var(--icon-primary);

    svg {
        width: $avatar-size;
        height: $avatar-size;
    }


    &__text {
        margin-left: 10px;
        margin-right: 10px;
    }

    &__name {
        font-family: $font-headings;
        font-size: 18px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.22;
        letter-spacing: normal;
        text-align: left;
    }

    &__role {
        font-family: $font-default;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        text-align: left;
    }
}

.logout-error {
    display: flex;
    align-items: center;
    border: none;
    background-color: transparent;
    color: var(--danger);
    cursor: pointer;
    margin-left: auto;
    
    &__icon {
        size: $avatar-size;

        max-height: $avatar-size;
        &:hover {
            color: var(--danger-hovered);
        }
        &:active {
            color: var(--danger-pressed);
        }
    }

    svg {
        width: $avatar-size;
        min-height: $avatar-size;
        height: $avatar-size;
    }
}
