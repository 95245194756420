@use "src/scss/config" as *;

.fullscreen-loader {
  &__image {
    animation: UpDown 1s ease-in-out infinite both;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 50%;
    max-height: 50%;
  }

  &__children {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 50%;
    max-height: 50%;
  }
}

@keyframes UpDown {
  0% {
    transform: translate(-50%, -48%);
  }

  50% {
    transform: translate(-50%, -52%);
  }

  100% {
    transform: translate(-50%, -48%);
  }
}