@use "src/scss/config" as *;

.footer {
    $root: &;

    display: flex;
    flex-wrap: nowrap;
    background-color: var(--bg-default);
    color: var(--ui-base);
    padding: 20px 0;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2em;
    position: absolute;
    bottom: 2em;
    left: 5%;
    width: 90%;

    @include media(">lg") {
        padding: 1rem 1em 0 1em;
        flex-direction: row;
    }

    @include media(">xl") {
        padding: 0 3rem;
    }

    &__copyright {
        margin-left: 0;
        text-decoration: none;
    }

    &__verison {
        text-decoration: underline;
        font-size: 1em;
        margin-left: 0.5em;
    }

    &__links {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-evenly;
        flex-direction: column;

        @include media(">sm") {
            width: 40%;
            justify-content: flex-end;
            flex-direction: row;
        }

    }

    &__link {
        color: var(--ui-base);
        text-decoration: none;
        font-size: 1.4rem;
        display: block;
        text-align: center;
        padding: auto 0;
        font-size: 1em;

        @include media(">sm") {
            margin: auto 0;
            padding: auto 1rem;
            padding-left: 2rem
        }

        &:hover {
            color: var(--nav-links-hovered);
        }

        &:active {
            color: var(--nav-links);
            text-decoration: underline;
        }

        &:disabled {
            color: var(--link-disabled);
        }
    }

    &__version {
        color: var(--ui-base);
        padding: 1rem 0;
        text-align: center;
        display: flex;
        flex-direction: row;

        @include media(">xl") {
            margin: auto auto auto 0;
            text-align: left;
        }
    }
}