@use "src/scss/config" as *;

.top {
    $root: &;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;

    @include media(">md") {
        flex-direction: row;
        align-self: auto;
    }

    &--center {
        justify-content: center;
    }

    .buttons {
        display: flex;
        align-self: flex-end;
    }
}
