@use "src/scss/config" as *;

.instance-status {
  display: flex;
  align-items: flex-start;
  column-gap: 0.8rem;
  width: 160px;
  text-align-last: start;


  &--details {
    width: auto;
  }

  svg {
    flex: 0 0 20px;
    vertical-align: top;
    margin-right: 5px;
  }

  svg.warning {
    fill: var(--warning);
  }

  svg.success {
    fill: var(--success);
  }

  &__error {
    font-size: $font-xxs;
    color: var(--danger);
  }
}