@use "src/scss/config" as *;

:root {
    --reach-listbox: 1;
}

.select {
    $root: &;

    &--big {
        width: 100%;
        max-width: 400px;

        #{$root}__input,
        #{$root}__button {
            height: 55px;
        }

        #{$root}__button {
            padding: 1.5rem;
            font-size: 1.4rem;
        }
    }

    &--small {
        width: 15%;
        max-width: 100px;

        #{$root}__input,
        #{$root}__button {
            height: 40px;
        }

        #{$root}__button {
            padding: 1rem;
            font-size: 1.4rem;
        }
    }

    &__container {
        outline: none;
        z-index: $z-select;
        display: block;
        margin-top: 0.8rem;
        padding: 0.25rem 0;
        min-width: -moz-fit-content;
        min-width: min-content;
        border-radius: 4px;

        &[hidden] {
            display: none;
        }

        &[hidden] {
            display: none;
        }
    }

    &__arrow {

        &--down,
        &--up {
            height: 100%;
            display: block;
            font-size: 1.5rem;
            fill: var(--icon-primary);
        }

        &--up {
            display: none;
        }

        [data-expanded] &--down {
            display: none;
        }

        [data-expanded] &--up {
            display: block;
        }
    }

    &__input,
    &__button {
        height: 100%;
        border-radius: 4px;
        background-color: var(--bg-container);
    }

    &__button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        user-select: none;
        -webkit-user-select: none;
        cursor: default;
        border: 1px solid var(--border-light);
        outline: none;

        &:hover,
        &:active,
        &:focus,
        #{$root}__input[data-state="expanded"] & {
            outline: none;
            border: 1px solid var(--border-drag-drop);
        }

        &[aria-disabled="true"] {
            opacity: 0.5;
        }
    }

    &__input {
        box-shadow: 0 2px 2px 0 var(--shadow-box);
    }

    &__list {
        margin: 0;
        padding: 0;
        list-style: none;
        overflow-x: hidden;
        font-family: $font-default;
        font-size: $font-xs;
        color: var(--paragraphs);
        font-weight: 400;
        border-radius: 4px;
        background: var(--bg-container);
        box-shadow: 0 2px 3px 1px var(--select-shadow);
        max-height: 150px;

        &:focus {
            outline: none;
        }
    }

    &__icon {
        display: flex;
        font-size: $font-xxs;
        align-items: center;
        margin-left: 2rem;
        color: var(--btn-danger-text);
        background-color: var(--danger);
        padding: 0.4rem 0.8rem;
        border-radius: 20px;
    }

    &__option {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0.8rem 2rem;
        white-space: nowrap;
        user-select: none;
        -webkit-user-select: none;

        &[data-current-nav] {
            background: var(--bg-selected);
            color: var(--paragraphs);
        }

        &[data-current-selected][data-confirming] {
            animation: flash 100ms;
            animation-iteration-count: 1;
        }

        &[aria-disabled="true"] {
            opacity: 0.5;
        }
    }

    [data-reach-listbox-arrow] {
        height: 100%;
    }
}
