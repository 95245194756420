@use "src/scss/config" as *;

.payment-loader-content {
    &__title {
        font-size: $font-m;
        font-family: $font-headings;
        text-align: center;
    }

    &__subtitle {
        margin-bottom: 1em;
        font-size: $font-s;
        font-family: $font-default;
        text-align: center;
    }
}