@use "src/scss/config" as *;

.sequence-add {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;

  @include media(">lg") {
    flex-direction: row;
    align-items: flex-start;
  }

  &__samples {
    width: 100%;
    padding: 1.5rem;
    border-radius: 10px;
    background-color: var(--bg-panel);
    margin-top: 2rem;
  }
}

.sample {
  position: relative;

  &__label {
    top: -8px;
  }
}

.add_sequence {
  $root: &;
  &__attention-animation {
      @keyframes pulse {
        0% {
          transform: scale(0.7);
          opacity: 0.5;
        }
  
        20% {
          opacity: 0.5;
        }
  
        50% {
          transform: scale(1);
          opacity: 0.8;
        }
  
        80% {
          opacity: 0.5;
        }
  
        100% {
          transform: scale(0.7);
          opacity: 0.5;
        }
      }
  
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background-color: var(--ui-accent);
      position: absolute;
      transform-origin: center center;
      animation: pulse 4s infinite;
      margin-right: 60px;
    }
}

