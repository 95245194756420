@use "src/scss/config" as *;

.product-trial {
    $root: &;
    margin: 5em auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 435px;

    &__label {
        color: var(--menu-button-danger);
        font-weight: 650;
    }

    &__crossed {
        color: var(--bg-border);
        text-decoration: line-through;
        font-weight: 600;
    }

    &__heading {
        margin-top: 2em;
    }

    &__subtitle {
        width: 95%;
        margin: 0 auto 2rem;
        text-align: center;
    }

    &__text {
        margin-bottom: 0.5em;
    }

    &__img {
        width: 100%;
        max-width: 300px;

        @include media(">lg") {
            width: 500px;
            max-width: 800px;
        }
    }

    &__container {
        background-color: var(--table-background);
        border-radius: 10px;
        width: 50%;
        display: flex;
        flex-direction: column;
        padding: 2em;
    }

    &__container-top {
        margin-bottom: 1em;
    }

    &__container-top>div {
        display: flex;
        align-items: center;
        margin-bottom: 1em;
    }

    &__container-top p {
        margin-right: 0.5em;
    }

    &__content {
        display: flex;
    }

    &__content-left {
        flex-grow: 1;
    }

    &__image {

        @include media(">lg") {
            margin-right: 4em;
            min-height: 90%;
        }

        @media (max-width: 768px) {
            display: none;
        }
    }

    &__content {
        display: flex;
        width: 100%;
        justify-content: space-around;
    }

    &__video {
        min-width: 100%;
    }


}