@use "src/scss/config"as *;

.btn-circle {
    display: flex;
    border: none;
    cursor: pointer;
    color: var(--icon-primary);
    box-shadow: 0 3px 6px var(--shadow-nav);
    background: var(--bg-btn-circle-default);
    align-items: center;
    border-radius: 2rem;
    width: 3.5rem;
    height: 3.5rem;

    svg {
        margin: auto;
        fill: var(--icon-primary);
    }

    &:hover {
        color: var(--icon-hover);
        background: var(--bg-btn-circle-hover);

        svg {
            fill: var(--icon-hover);
        }
    }

    &:active {
        background: var(--bg-btn-circle-pressed);
        color: var(--icon-hover);
    }

    &:disabled {
        background: var(--bg-btn-circle-disabled);
        color: var(--icon-disabled);

        svg {
            fill: var(--icon-disabled);
        }
    }
}