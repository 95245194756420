.upload-button {
  position: relative;

  &>label {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    cursor: pointer;

    &>input {
      position: relative;
      width: 1px;
      height: 1px;
      text-align: right;
      -moz-opacity: 0;
      opacity: 0;
      z-index: 2;
    }
  }
}