@use "src/scss/config" as *;

.btn-group {
    display: flex;

    &--center {
        justify-content: center;
    }

    &--space-between {
        justify-content: space-between;
    }

    &--right {
        justify-content: right;
    }

    &--margin-1 {
        margin: 1em;
    }

    &--margin-2 {
        margin: 2em;
    }

    &--gap-1 {
        gap: 1em;
    }

    &--gap-2 {
        gap: 2em;
    }

    &--float-left {
        float: left;
    }

    &--float-right {
        float: right;
    }

    .btn {
        display: flex;
    }
}
