@use "src/scss/config" as *;

.popover {
    &__content {
        border-radius: 10px;
        padding: 20px;
        background-color: var(--tooltip-onboarding-bg);
        color: var(--title-head);
        width: 25em;
        min-height: 24em;
        pointer-events: all;

        @include media("<xl") {
            display: none;
        }
    }

    &__elements {
        margin: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 20em;
    }

    &__arrow {
        fill: var(--tooltip-onboarding-bg);
        height: 1em;
        width: 2em;
    }

    &__bottom-container {
        display: flex;
        margin: 2em 0 0;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: flex-end;
    }

    &__text-container {
        position: relative;
    }

    &__title {
        color: var(--title-head) !important;
    }

    &__buttonPanel {
        margin: 2em;
    }

    &__icon {
        display: flex;
        justify-content: center;
    }

    &__pointerEventsNone {
        // pointer-events: none;
    }
}
