@use "src/scss/config" as *;

.sequence-start {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  @include media(">lg") {
    flex-direction: row;
  }

  &__actions,
  &__args,
  &__info,
  &__buttons {
    width: 100%;
  }

  &__actions {
    margin-top: 2em;
  }

  &__data {
    margin-right: 2em;
  }

  &__args {
    @include media(">lg") {
      width: 55%;
    }
  }

  &__info {
    @include media(">lg") {
      width: 45%;
    }
  }
}
