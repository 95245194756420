@use "src/scss/config"as *;

$circle-loader-width: 32px;
$circle-loader-height: 32px;
$circle-loader-border-size: 6px;
$circle-loader-font-size: 9px;

.circle-loader {
  display: inline-block;
  height: $circle-loader-width;
  width: $circle-loader-height;
  position: relative;
  animation: rotation 1s linear both;
  animation-iteration-count: infinite;

  .inner {
    position: absolute;
    z-index: 6;
    top: 50%;
    left: 50%;
    width: $circle-loader-width - $circle-loader-border-size;
    height: $circle-loader-height - $circle-loader-border-size;
    margin: calc(($circle-loader-width - $circle-loader-border-size) / -2) 0 0 calc(($circle-loader-height - $circle-loader-border-size) / -2);
    background: var(--bg-container);
    border-radius: 100%;

  }

  .number {
    position: absolute;
    top: 50%;

    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    font-size: $circle-loader-font-size;
    font-weight: 500;
    color: var(--primary);
  }

  .bar {
    position: absolute;
    height: 100%;
    width: 100%;
    background: var(--bg-container);
    border-radius: 100%;
    clip: rect(0px, $circle-loader-width, $circle-loader-height, calc($circle-loader-width / 2));
  }

  .circle .bar .progress {
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 100%;
    clip: rect(0px, calc($circle-loader-width / 2), $circle-loader-height, 0px);
    background: var(--primary);
  }

  .circle .left .progress {
    z-index: 1;
  }

  .circle .right {
    transform: rotate(180deg);
    z-index: 3;

  }

  .circle--animated {
    &.circle .left .progress {
      z-index: 1;
      animation: left 4s linear both;
    }

    &.circle .right .progress {
      animation: right 4s linear both;
      animation-delay: 4s;
    }
  }

  &--color-primary {
    .number {
      color: var(--primary);
    }

    .circle .bar .progress {
      background: var(--primary);
    }
  }

  &--color-secondary {
    .number {
      color: var(--secondary);
    }

    .circle .bar .progress {
      background: var(--secondary);
    }
  }

  &--color-tertiary,
  &--color-success {
    .number {
      color: var(--tertiary);
    }

    .circle .bar .progress {
      background: var(--tertiary);
    }
  }

  &--color-danger,
  &--color-error {
    .number {
      color: var(--danger);
    }

    .circle .bar .progress {
      background: var(--danger);
    }
  }

  &--color-warning {
    .number {
      color: var(--warning);
    }

    .circle .bar .progress {
      background: var(--warning);
    }
  }

  @for $i from 0 through 100 {
    .circle--#{$i} {
      $left: min($i/50 * 180, 180);
      $right: max(($i - 50)/50 * 180, 0);

      .left .progress {
        transform: rotate($left * 1deg)
      }

      .right .progress {
        transform: rotate($right * 1deg)
      }
    }
  }
}

@keyframes rotation {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes left {
  100% {
    transform: rotate(180deg);
  }
}

@keyframes right {
  100% {
    transform: rotate(170deg);
  }
}