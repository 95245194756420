@use "src/scss/config" as *;

.CLItooltip {
    padding: 8px 10px;
    border: none;
    border-radius: 10px;
    font-size: 14px;
    font-family: $font-default;
    background-color: var(--tooltip-info-bg);
    color: var(--tooltip-info-text);
    width: 40rem;
    
    &__title {
        font-weight: 600;
    }

    &__arrow {
        fill: var(--tooltip-info-bg);
    }

    .tab-menu {
        color: white;
    }
}