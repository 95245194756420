@use "src/scss/config" as *;

.info-box {
	background-color: var(--ui-base);
	max-width: 400px;
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1.5em 3em;

	&__text {
		color: var(--font-head);

		em {
			color: var(--primary);
			font-weight: normal;
			font-size: 1.8rem;
			padding-right: 0.5rem;
		}
	}

	&__label {
		background-color: var(--tag-background);
		color: var(--tag-text);
		border-radius: 10px;
		position: absolute;
		padding: 0.5rem 1rem;
		font-size: 1.6rem;
		top: -20%;
		left: 3%;
	}

	&__btn {
		white-space: nowrap;
	}
}
