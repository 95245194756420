@use "src/scss/config" as *;

.modal {
    &--dialog {
        min-width: 555px;

        &__header,
        &__content {
            align-self: start;
        }

        &__content {
            align-self: start;
        }
    }
}
