.light {
    /** *********** COLORS *********** **/

    // Backgrounds
    --bg-default: #f0f4f8;
    --bg-container: #fff;
    --bg-footer: #3c3d3e;
    --bg-panel: #f5f5f5;
    --bg-head: #002849;
    --bg-head-light: #f5f5f5;
    --bg-indicators: #dbdbdb;
    --bg-json: #fbfbfb;
    --bg-command: #f2f2f2;
    --bg-console: #3a3a3a;
    --bg-border: #6e6e6e;
    --bg-nav--console: #292929;
    --bg-nav-link: #3D607D;
    --bg-navbar: #002849;
    --bg-drag-drop: #f0f4f8;
    --bg-icon: #f0f4f8;
    --bg-btn-circle-default: #ffffff;
    --bg-btn-circle-hover: #ffffff;
    --bg-btn-circle-pressed: #002849;
    --bg-btn-circle-disabled: #e9e9e9;
    --bg-btn-disabled: #C6C6C6;
    --bg-banner: #ffffff;
    --bg-selected: #F4F3F3;

    // Shadows
    --shadow-nav: rgba(0, 0, 0, 0.15);
    --shadow-box: #dbdbdb;
    --shadow-bg: #f0f0f0;

    // Borders
    --border-light: #f2f2f2;
    --border-dark: #3c3d3e;
    --border-btn: #002849;
    --border-btn-primary-hovered: #f5943e;
    --border-drag-drop: #f07e19;


    //Tabs
    --tab: #002849;

    // UI colors
    --ui-base: #002849;
    --ui-accent: #f07e19;
    --ui-accent-hovered: #f5943e;

    // Buttons
    --primary: #f07e19;
    --primary-pressed: #d06d16;
    --primary-hovered: #f5943e;

    --secondary: transparent;
    --secondary-pressed: #002849;
    --secondary-hovered: #E7F4FF;
    --outline: #3c3d3e;

    --tertiary: #0a6c74;
    --tertiary-pressed: #044e54;
    --tertiary-hovered: #14919b;

    --danger: #ba2525;
    --danger-pressed: #911111;
    --danger-hovered: #d64545;

    --link: #000000;
    --link-disabled: #C6C6C6;


    //--warning: #C85208;
    --warning: #F5943E;
    --warning-pressed: #cf782c;
    --warning-hovered: #f5a661;

    --confirm: #0a6c74;
    --confirm-pressed: #044e54;
    --confirm-hovered: #14919b;

    --error: #ba2525;
    --success: #0a6c74;

    // Typography
    --headings: #002849;
    --subtitles: #002849;
    --paragraphs: #000;
    --font-head: #e0e0e0;
    --title-head: #ffffff;
    --link-label: #4d4d4d;
    --json-default: #212121;

    --nav-links: #fbfbfb;
    --nav-background-hovered: #3D607D;
    --nav-links-active: #002849;
    --nav-links-hovered: #C75302;
    --main-nav-link-hovered: #ffffff;

    --btn-primary-text: #002849;
    --btn-secondary-text: #002849;
    --btn-secondary-text-hovered: #002849;
    --btn-secondary-text-pressed: #fff;
    --btn-confirm-text: #fff;
    --btn-danger-text: #fff;
    --btn-disabled-text: #404040;

    // Navigation
    --top-bar: #fff;
    --tab-nav-text: #fff;
    --tab-nav-color: #002849;

    // Icons
    --icon-primary: #002849;
    --icon-secondary: #f0f0f0;
    --icon-hover: #f07e19;
    --icon-disabled: #b0b0b0;
    --icon-seq: #1770c9;
    --icon-inst: #f07e19;

    // Logs
    --logs: #417bb4;
    --logs-info: #969a33;
    --logs-deubg: #634274;
    --logs-error: #e95353;

    // Radio button
    --radio-background: #fff;
    --radio-active-default: #f07e19;
    --radio-active-hover: #f07d19eb; // shadow opasity 10%
    --radio-active-disabled: #f07d1985; // opacity of color 50%
    --radio-inactive-default: #00000082; // opacity of color 60%
    --radio-inactive-hover: #00000090; //opacity of color 60%, shadow opacity 10%
    --radio-inactive-disabled: #00000040; // opracity of color 30%

    // Input
    --input-default: #dbdbdb;
    --input-active: #f07e19;
    --input-disabled: #dbdbdb;
    --input-error: #ba2525;

    // Checkbox
    --checkbox-default: #000000; // opacity 40%
    --checkbox-hovered: #000000; // opacity 60%, shadow 10%
    --checkbox-tapped: #f07e19;
    --custom-checkbox-border: #E6EBF0;

    // Toast
    --toast-danger: #ba2525;
    --toast-success: #0a6c74;
    --toast-text: #fff;

    // Tooltip
    --tooltip-info-bg: #3c3d3e;
    --tooltip-info-text: #fff;
    --tooltip-onboarding-bg: #1770c9;
    --tooltip-onboarding-text: #fff;

    // Tag
    --tag-background: #BEF8FD;
    --tag-background-yellow: #f7e3ab;
    --tag-text: #002849;

    // Quote
    --quote-info: #F4DFA5;
    --quote-text: #121212;
    --quote-icon: #002849;

    // Table
    --table-background: #F7F7F7;

    //Select
    --select-border: #DEDEDE;
    --select-shadow: #00000040;

    // Menu Button
    --menu-button-border: #dbdbdb;
    --menu-button-background: #ffffff;
    --menu-button-background-hover: #e9e9e9;
    --menu-button-text: #212121;
    --menu-button-text-hover: #1f1f1f;
    --menu-button-icon: #002849;
    --menu-button-icon-hover: #1770c9;
    --menu-button-danger: #ba2525;
    --menu-button-danger-hovered: #d64545;

    // Banner
    --banner-gradient: linear-gradient(90deg,
            rgba(255, 255, 255, 1) 0%,
            rgba(217, 233, 244, 1) 100%);
    --banner-title: #1d5ea9;
}