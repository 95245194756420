@use "src/scss/config" as *;

.container {
    max-width: 1200px;
    margin: 0 auto;

    &--wide {
        max-width: 100%;
    }
}
