@use "src/scss/config" as *;

.error {
    &__logo {
        &--basic {
            display: block;

            @include dark-mode {
                display: none;

            }
        }

        &--white {
            display: none;

            @include dark-mode {
                display: block;

            }
        }
    }

    &__container {
        display: flex;
        min-height: 70vh;
        place-items: center;
    }

    &__box {
        margin: auto;
        text-align: center;
        max-width: 680px;
        min-width: 390px;
        padding: 5rem;
    }

    &__link {
        cursor: pointer;
        margin-top: 2rem;
        font-size: $font-s;
        font-weight: 600;
        border: none;
        background: none;
        color: var(--primary);
        text-decoration: underline;
    }

    &__title {
        margin-bottom: 1rem;
    }

    &__text {
        margin: 1rem 5rem 0;
    }

    &__verify-email-success {
            display: flex;
            justify-content: center;
            align-items: center;
    }

    &__verify-email-icon {
        margin-right: 1em;
        fill: var(--success);
    }
}