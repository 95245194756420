@use "src/scss/config" as *;

.a11-panel {
    position: fixed;
    z-index: $z-nav;
    right: 0;
    top: 15rem;
    padding: 0.5rem 0.5rem;
    display: flex;
    flex-direction: column;
    border-radius: 10px 0 0 10px;
    background-color: var(--bg-panel);
    box-shadow: 0 3px 6px var(--shadow-nav);

    .btn {
        background: none;
        border: none;
        margin: 0;
        padding: 0.5rem 0.5em;
    }

    .icon {

        &--contrast,
        &--font-smaller,
        &--font-bigger {
            color: var(--icon-primary);
        }
    }

    .text {
        color: var(--paragraphs);
    }

}