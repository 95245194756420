@use "src/scss/config" as *;

.box {
    width: 100%;
    min-height: 70%;
    background: var(--bg-container);
    border-radius: 5px;
    padding: 1.5em;
    clear: both;

    @include media(">lg") {
        padding: 3em;
    }

    &-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: stretch;

        @include media(">lg") {
            &--column {
                flex-direction: column;
            }

            &--row {
                flex-direction: row;
            }
        }
    }

    &--shadow {
        box-shadow: 0px 1px 4px var(--shadow-box);
    }

    &--dashed {
        border: 2px dashed var(--shadow-box);
    }

    &--solid {
        border: 1px solid var(--shadow-box);
    }

    &--link {
        text-decoration: none;
        color: inherit;

        &:hover {
            color: var(--paragraphs);
            text-decoration: none;
            box-shadow: 0px 1px 4px var(--shadow-box);
        }
    }

    &--item {
        flex: 0 0 100%;
        display: inline-block;
        position: relative;
        min-height: 100px;
        padding: 2.5em 2.5em;
        border-radius: 3px;

        @include media(">md") {
            flex: 0 0 45%;
        }

        @include media(">lg") {
            flex: 0 0 30%;
        }
    }

    &--download {
        display: flex;
        font-size: $font-s;
        align-items: center;
        padding: 1.5rem;
        min-width: 210px;
        width: fit-content;
        justify-content: space-around;
    }

    &--item &__title {
        margin-top: -2.5em;
        margin-left: -2.5em;

        span {
            display: inline-block;
            padding: 0.5em 50px 0.5em 1em;
            border-radius: 3px 0 30px 0;
            background-color: var(--shadow-bg);
            font-size: 1.4rem;
        }
    }

    &--item &__title-children {
        float: right;
        margin-top: 1em;
        margin-right: -1em;
    }
}
