@use "src/scss/config" as *;

.nav {
    $root: &;

    background: var(--ui-base);
    box-shadow: 0 3px 6px var(--shadow-nav);
    position: relative;
    height: 10px;

    @include media(">xl") {
        height: auto;
    }

    &__links {
        padding: 8em 0 1em;
        display: none;
        min-width: 220px;

        @include media(">xl") {
            padding: 8em 0 1em;
            min-height: calc(100vh - 180px);
            display: block;
        }
    }

    &__toggler {
        cursor: pointer;
        display: flex;
        position: absolute;
        text-transform: uppercase;
        top: 6px;
        left: 50%;
        border-radius: 5px;
        transform: translateX(-50%);
        padding: 5px 10px;
        border: 1px solid transparent;
        color: var(--nav-links);
        background: var(--ui-base);
        z-index: $z-nav;

        @include media(">xl") {
            display: none;
        }
    }

    &__link {
        background: none;
        border: none;
        cursor: pointer;
        outline: inherit;
        display: flex;
        font-size: 1.6rem;
        font-family: $font-headings;
        align-items: center;
        text-align: left;
        color: var(--nav-links);
        text-decoration: none;
        padding: 1.5rem 4rem 1.5rem 2rem;
        width: 100%;

        &.popover-animation {
            @keyframes pulse {
                0% {
                    transform: scale(0.3);
                    opacity: 0;
                }

                20% {
                    opacity: 0.9;
                }
            
                50% {
                    transform: scale(1);
                        opacity: 0.5;
                }

                80% {
                    opacity: 0.9;
                }
            
                100% {
                    transform: scale(0.3);
                    opacity: 0;
                }
            }

            .icon {
                z-index: 2;
            }

            .attention-animation {
                width: 38px;
                height: 38px;
                border-radius: 50%;
                background-color: var(--ui-accent);
                position: absolute;
                transform-origin: center center;
                animation: pulse 4s infinite;
                left: 0.55em;
            }
        }

        &:hover,
        &:focus {
            text-decoration: underline;
            background-color: var(--nav-background-hovered);
            color: var(--main-nav-link-hovered);

            .icon {
                fill: var(--main-nav-link-hovered);
            }
        }

        .icon {
            fill: var(--nav-links);
        }

        .arrow-icon {
            display: none;
        }

        &.is-active {
            background: var(--bg-default);
            color: var(--nav-links-active);

            .icon {
                fill: var(--primary);
            }

            .arrow-icon {
                display: none;
            }
        }

        &.disabled {
            pointer-events: none;
            // TODO: colors?
        }
    }

    &__label {
        margin-left: 2rem;
    }

    &__text {
        font-size: 1.5em;
        line-height: 1.5em;
        margin-left: 0.5em;
    }

    &.open {
        height: auto;

        #{$root}__links {
            display: flex;
            flex-wrap: wrap;
            padding: 7em 0 1em;
        }

        #{$root}__link {
            flex: 1 0 50%;
            padding-left: 2em;

            @include media(">md") {
                flex: 1 0 21%;
            }
        }

        #{$root}__toggler {
            border: 1px solid white;
        }

        #{$root}__burger {
            transform: rotate(45deg);

            &:before {
                border: 1px solid #ecf0f1;
            }

            .line {
                width: 20px;
            }

            .line:nth-child(2) {
                opacity: 0;
            }

            .line:nth-child(1) {
                transform: translateY(8px);
            }

            .line:nth-child(3) {
                transform: translateY(-8px) rotate(90deg);
            }
        }
    }

    &__burger {
        cursor: pointer;
        position: relative;
        width: 30px;
        height: 30px;
        transition: all 0.3s ease-in-out;

        &:before {
            content: "";
            position: absolute;
            box-sizing: border-box;
            width: 35px;
            height: 35px;
            border: 5px solid transparent;
            top: calc(50% - 18px);
            left: calc(50% - 18px);
            border-radius: 100%;
            transition: all 0.3s ease-in-out;
        }

        .line {
            width: 25px;
            height: 3px;
            background-color: #ecf0f1;
            display: block;
            margin: 5px auto;
            transition: all 0.3s ease-in-out;
        }
    }
}