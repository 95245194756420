/**
 * Cookie widget - override standard CookieBot widget styles
 */
#CookiebotWidget {
    right: 5px;
    left: auto !important;

    .CookiebotWidget-logo {
        svg {
            display: none !important;
        }

        height: 48px;
        width: 48px;
        background: url("https://scramjet.org/images/cookie.svg");
    }

    & &-widgetContent .CookiebotWidget-body .CookiebotWidget-main-logo {
        display: none;
    }

    & &-buttons {

        #CookiebotWidget-btn-withdraw,
        #CookiebotWidget-btn-change {
            border-radius: 30px;
            width: 250px;
            font-size: 15px;
            margin: auto;
        }
    }
}
