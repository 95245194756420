@use "src/scss/config" as *;

.list {
    margin: 0;

    &--icons {
        padding: 0;
        list-style: none;
    }

    &__item {
        margin: 0 0.5em 0.5em 0;
        align-items: center;
        display: flex;
        justify-content: flex-start;
    }

    &__text {
        font-family: $font-default;
        line-height: 1.8em;
        margin-left: 0.8em;
    }

    &__icon {
        color: var(--tertiary);
        min-width: 25px;
        width: 25px;
        height: 100%;
    }
}
