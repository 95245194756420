@use "src/scss/config" as *;

.toast {
    display: flex;
    padding: 6px;
    border-radius: 5px;
    border-width: 1px;
    min-height: 47px;
    min-width: 341px;
    align-items: center;
    box-shadow: 0px 3px 8px rgb(0 0 0 / 25%);
    box-sizing: border-box;

    animation: slide-left 0.5s cubic-bezier(0.5, 1, 0.89, 1) both;

    &__text {
        width: 100%;
        padding-left: 10px;
        margin: auto;
        text-align: left;
        color: var(--toast-text);
    }

    &__buttons {
        float: right;
        margin: 0 1em 0 2em;
        color: var(--toast-text);
    }

    &--error {
        background: var(--error);
    }

    &--success {
        background: var(--success);
    }

    &--closing {
        animation: slide-right 0.5s cubic-bezier(0.5, 1, 0.89, 1) both;
    }
}

.toast-placeholder {
    position: absolute;
    top: 7em;
    right: 0;
    padding-right: 1em;
    overflow: hidden;
    z-index: $z-toast;

    &.scrolled {
        position: fixed;
        top: 1em;
    }

    &>* {
        margin-bottom: 1em;
    }
}

@keyframes slide-left {
    0% {
        transform: translateX(110%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes slide-right {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(110%);
    }
}