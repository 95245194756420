@use "src/scss/config" as *;

.top-bar {
    grid-area: header;
}

.nav {
    grid-area: sidebar;
}

.main-content {
    grid-area: main;
}

.layout {
    display: block;


    @include media(">xl") {
        display: grid;
        grid-template-columns: 1fr 11fr;
        grid-template-rows: auto;
        grid-template-areas:
            "header header"
            "sidebar main";
    }

    &--mini-beta {
        @include media(">xl") {
            display: grid;
            grid-template-columns: 1fr 11fr;
            grid-template-rows: auto;
            grid-template-areas:
                "header header"
                "main main";
        }
    }

    &--headless {
        @include media(">xl") {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: auto;
            grid-template-areas:
                "header header"
                "main main";
        }
    }
}

.flex {
    @for $i from 0 through 5 {
        &--gap-#{$i} {
            gap: #{$i}rem;
        }
    }
}
