@use "src/scss/config" as *;

.email {

    &__status {
        display: flex;
        margin: auto;
        font-weight: 600;
        min-height: 50px;
        font-size: $font-s;
        align-items: flex-end;
        justify-content: center;
    }

    &__link {
        display: flex;
        margin: auto;
        align-items: flex-end;
        justify-content: center;
        margin-top: 3rem;
    }

    &__status {
        &--error {
            color: var(--error);
        }

        &--success {
            color: var(--success);
        }
    }

    &__icon {
        margin-left: -2rem;
        margin-right: 1rem;
    }
}
