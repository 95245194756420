@use "src/scss/config" as *;

h1,
.headline-1 {
    font-size: 2.8rem;
    font-weight: 600;
    color: var(--headings);
    line-height: 1.2em;
}

h2,
.headline-2 {
    font-size: 2.6rem;
    font-weight: 600;
    color: var(--headings);
}

h3,
.headline-3 {
    font-size: 2.4rem;
    font-weight: 600;
    color: var(--headings);
}

h4,
.subtitle-1 {
    font-size: 1.8rem;
    font-weight: 600;
    color: var(--subtitles);
}

h5,
.subtitle-2 {
    font-size: 1.6rem;
    font-weight: 600;
    color: var(--subtitles);
}

h1,
h2,
h3,
h4,
h5,
.headline-1,
.headline-2,
.headline-3,
.subtitle-1,
.subtitle-2 {
    color: var(--subtitles);
    font-family: $font-headings;
    font-weight: 600;
    margin: 0;
    padding: 0;
}

p,
a,
.body-1,
.body-2,
.body-3 {
    font-family: $font-default;
}

.body-1 {
    font-size: 1.6rem;
}

p,
.body-2 {
    font-size: 1.4rem;
}

p,
.body-3 {
    font-size: 1.4rem;
}

p,
a {
    margin: 0;
}

a {
    color: var(--link);

    &:hover {
        color: var(--nav-links-hovered);
        text-decoration: none;
    }

    &:active {
        color: var(--link);
        text-decoration: underline;
    }

    &:disabled {
        color: var(--link-disabled);
    }
}

i {
    font-weight: 200;
}

ol,
ul {
    font-size: 1.4rem;
}

.overline,
.caption {
    font-family: $font-headings;
    font-size: 1.4rem;
    font-weight: 400;
    color: var(--paragraphs);
}

.layout {
    font-family: $font-default;
    font-weight: 400;
    color: var(--paragraphs);
}


.align {
    &-left {
        text-align: left;
        margin-right: auto;
    }

    &-right {
        text-align: right;
        margin-left: auto;
    }

    &-center {
        text-align: center;
    }
}

.margin {
    &--t-05 {
        margin-top: 0.5rem;
    }

    @for $i from 0 through 5 {
        &--b-#{$i} {
            margin-bottom: #{$i}rem;
        }

        &--t-#{$i} {
            margin-top: #{$i}rem;
        }

        &--l-#{$i} {
            margin-left: #{$i}rem;
        }

        &--r-#{$i} {
            margin-right: #{$i}rem;
        }
    }
}

.border {

    &--b,
    &--t,
    &--l,
    &--r {
        padding: 1em;
    }

    &--b {
        border-bottom: 1px solid var(--border-light);
    }

    &--t {
        border-top: 1px solid var(--border-light);
    }

    &--l {
        border-left: 1px solid var(--border-light);
    }

    &--r {
        border-right: 1px solid var(--border-light);
    }
}
