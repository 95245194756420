@use "src/scss/config" as *;

html {
    font-size: 62.5%;
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    line-height: 1.5;
    margin: 0;
    padding: 0;
    padding-right: 0 !important; // remove invalid @reach/dialog style when open
}

.main-content {
    width: auto;
    position: relative;
    background: var(--bg-default);
    min-height: calc(100vh - 70px);
    position: relative;
    padding: 4rem 6rem 12rem 6rem;

    @include media(">md") {
        padding: 4rem 6rem 10 6rem;
        min-height: calc(100vh - 70px);
    }

    @include media(">xl") {
        min-height: calc(100vh - 60px);
        padding: 4rem 6rem 8rem 6rem;
    }

    @include media(">xxl") {
        padding: 4rem 6rem 8rem 6rem;
    }

    &--headless {
        padding: 1rem 1rem;

        @include media(">md") {
            padding: 2rem 6rem;
            min-height: 100vh;
        }

        @include media(">xl") {
            min-height: 100vh;
        }

        @include media(">xxl") {
            min-height: 100vh;
        }
    }
}

.title {
    display: flex;
    align-items: center;

    .icon {
        margin-right: 1.5rem;
        color: var(--icon-primary);
    }
}

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.break-line {
    line-break: anywhere;
}

.text-nowrap {
    white-space: nowrap;
}

.text-center {
    text-align: center;
}

.flex {
    display: flex;
}

.inline-flex {
    display: inline-flex;
}

.bold,
strong {
    font-weight: 600;
}

code {
    background: var(--bg-command);
    padding: 0.2em;
    font-size: 1.2rem;

    &.language-bash {
        background: none;
    }
}

em {
    color: var(--ui-accent);
    font-style: normal;
    font-weight: 800;
}

.max-width {
    &-600 {
        margin: 0 auto;
        max-width: 600px;
    }

    &-800 {
        margin: auto;
        max-width: 800px;
    }
}
