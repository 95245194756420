@use "src/scss/config" as *;

.btn {
    $root: &;

    display: block;
    border-radius: 5px;
    padding: 0.5em 1em;
    text-decoration: none;
    min-height: 40px;
    align-items: center;
    cursor: pointer;

    &__icon {
        display: flex;
        min-width: 22px;
        margin: auto;
    }

    &__image {
        margin: 0.5em auto;
    }

    &--image {
        display: flex;
        flex-direction: row-reverse;
        padding: 0 0.5em;
    }

    &--right {
        justify-content: flex-end;
    }

    &--left {
        justify-content: flex-start;
    }

    &--primary,
    &--secondary,
    &--danger,
    &--disabled {
        font-size: 1.6rem;
    }

    &--primary {
        border: 1px solid transparent;
        color: var(--btn-primary-text);
        background-color: var(--primary);

        &:hover {
            background-color: var(--primary-hovered);
            border: 1px solid var(--border-btn-primary-hovered);
            color: var(--btn-secondary-text);
        }

        &:active {
            background-color: var(--primary-pressed);
            color: var(--btn-primary-text);
            text-decoration: none;
        }
    }

    &--secondary {
        border: 1px solid var(--border-btn);
        color: var(--btn-secondary-text);
        background-color: var(--secondary);

        &:hover {
            background-color: var(--secondary-hovered);
            color: var(--btn-secondary-text-hovered);
            border: 1px solid var(--border-btn);
        }

        &:active {
            background-color: var(--secondary-pressed);
            border: 1px solid var(--border-btn);
            color: var(--btn-secondary-text-pressed);
            text-decoration: none;
        }
    }

    &--danger {
        border: 1px solid var(--danger);
        color: var(--btn-danger-text);
        background-color: var(--danger);

        &:hover {
            background-color: var(--danger-hovered);
            color: var(--btn-danger-text);
        }

        &:active {
            background-color: var(--danger-pressed);
        }
    }

    &--icon {
        color: var(--btn-secondary-text);
        border: 1px solid transparent;
        min-height: auto;
        padding: 0.5rem;
        font-size: 0;

        &:hover {
            background-color: transparent;
            color: var(--btn-secondary-text);
            border: 1px solid var(--border-btn);
        }

        &:active {
            color: var(--btn-secondary-text-pressed);
            background-color: var(--secondary-pressed);
            border: 1px solid var(--border-btn);
        }
    }

    &--action {
        background: transparent;
        color: var(--nav-links);
        font-size: $font-s;
        padding: 0.5rem;
        margin: 0;
        border: 0;

        &:hover {
            color: var(--nav-links-hovered);
        }

        #{$root}__icon {
            padding: 0 1rem;
        }
    }

    &--link,
    &--leave {
        display: inline;
        background: transparent;
        width: auto;
        min-height: auto;
        height: auto;
        padding: 0;
        margin: 0;
        border: 0;
        line-height: 1.2;
    }

    &--leave {
        color: inherit;
    }

    &--link {
        color: var(--primary);
    }

    &--disabled,
    &:disabled {
        border: 1px solid var(--bg-btn-disabled);
        color: var(--btn-disabled-text);
        background-color: var(--bg-btn-disabled);
        pointer-events: none;
    }

}