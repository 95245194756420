@use "src/scss/config" as *;

[data-reach-dialog-overlay] {
    z-index: $z-popup;
}

[data-reach-dialog-content] {
    background-color: var(--bg-banner);
}

.modal {
    $root: &;

    display: flex;
    border-radius: 10px;
    box-shadow: 3px 3px 30px;
    width: 90vw;
    max-width: 390px;
    flex-flow: column;
    align-items: center;
    position: relative;
    justify-content: space-around;

    &--left {
        #{$root}__header {
            width: 100%;
            text-align: left;
        }

        #{$root}__content {
            width: 100%;
            text-align: left;
        }

        #{$root}__buttons {
            align-self: flex-start;
        }
    }

    &--center {
        #{$root}__content {
            text-align: center;
        }

        #{$root}__buttons {
            justify-content: center;
        }
    }

    &--right {
        #{$root}__header {
            width: 100%;
            text-align: right;
        }

        #{$root}__content {
            width: 100%;
            text-align: right;
        }

        #{$root}__buttons {
            justify-content: right;
        }
    }

    &__btn--icon {
        border: none;
        cursor: pointer;
        background: transparent;
        position: absolute;
        top: 5px;
        right: 0;
    }

    &__image {
        max-width: 100%;
    }

    &__icon {
        align-self: center;
    }

    &__header {
        margin: 0 0 0.5rem;
    }

    &__content {
        text-align: center;
        color: var(--paragraphs);
    }

    &__buttons {
        display: flex;
        justify-content: center;

        .btn {
            margin: 1rem 0.5rem 0;
        }
    }
}
