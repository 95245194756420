@font-face {
    font-family: 'Open Sans';
    src: url('../../assets/fonts/OpenSans-Italic.woff2') format('woff2'),
        url('../../assets/fonts/OpenSans-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../../assets/fonts/OpenSans-Regular.woff2') format('woff2'),
        url('../../assets/fonts/OpenSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../../assets/fonts/OpenSans-LightItalic.woff2') format('woff2'),
        url('../../assets/fonts/OpenSans-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../../assets/fonts/OpenSans-ExtraBold.woff2') format('woff2'),
        url('../../assets/fonts/OpenSans-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../../assets/fonts/OpenSans-SemiBold.woff2') format('woff2'),
        url('../../assets/fonts/OpenSans-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../../assets/fonts/OpenSans-Light.woff2') format('woff2'),
        url('../../assets/fonts/OpenSans-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../../assets/fonts/OpenSans-BoldItalic.woff2') format('woff2'),
        url('../../assets/fonts/OpenSans-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../../assets/fonts/OpenSans-Italic.woff2') format('woff2'),
        url('../../assets/fonts/OpenSans-Italic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../../assets/fonts/OpenSans-Bold.woff2') format('woff2'),
        url('../../assets/fonts/OpenSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../../assets/fonts/OpenSans-SemiBoldItalic.woff2') format('woff2'),
        url('../../assets/fonts/OpenSans-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Jost';
    src: url('../../assets/fonts/Jost-ThinItalic.woff2') format('woff2'),
        url('../../assets/fonts/Jost-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Jost';
    src: url('../../assets/fonts/Jost-ExtraBold.woff2') format('woff2'),
        url('../../assets/fonts/Jost-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Jost';
    src: url('../../assets/fonts/Jost-SemiBoldItalic.woff2') format('woff2'),
        url('../../assets/fonts/Jost-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Jost';
    src: url('../../assets/fonts/Jost-ExtraBoldItalic.woff2') format('woff2'),
        url('../../assets/fonts/Jost-ExtraBoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Jost';
    src: url('../../assets/fonts/Jost-LightItalic.woff2') format('woff2'),
        url('../../assets/fonts/Jost-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Jost';
    src: url('../../assets/fonts/Jost-ExtraLight.woff2') format('woff2'),
        url('../../assets/fonts/Jost-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Jost';
    src: url('../../assets/fonts/Jost-Black.woff2') format('woff2'),
        url('../../assets/fonts/Jost-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Jost';
    src: url('../../assets/fonts/Jost-SemiBold.woff2') format('woff2'),
        url('../../assets/fonts/Jost-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Jost';
    src: url('../../assets/fonts/Jost-Bold.woff2') format('woff2'),
        url('../../assets/fonts/Jost-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Jost';
    src: url('../../assets/fonts/Jost-Italic.woff2') format('woff2'),
        url('../../assets/fonts/Jost-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Jost';
    src: url('../../assets/fonts/Jost-BoldItalic.woff2') format('woff2'),
        url('../../assets/fonts/Jost-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Jost';
    src: url('../../assets/fonts/Jost-Thin.woff2') format('woff2'),
        url('../../assets/fonts/Jost-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Jost';
    src: url('../../assets/fonts/Jost-MediumItalic.woff2') format('woff2'),
        url('../../assets/fonts/Jost-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Jost';
    src: url('../../assets/fonts/Jost-Regular.woff2') format('woff2'),
        url('../../assets/fonts/Jost-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Jost';
    src: url('../../assets/fonts/Jost-BlackItalic.woff2') format('woff2'),
        url('../../assets/fonts/Jost-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Jost';
    src: url('../../assets/fonts/Jost-Light.woff2') format('woff2'),
        url('../../assets/fonts/Jost-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Jost';
    src: url('../../assets/fonts/Jost-Medium.woff2') format('woff2'),
        url('../../assets/fonts/Jost-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Jost';
    src: url('../../assets/fonts/Jost-ExtraLightItalic.woff2') format('woff2'),
        url('../../assets/fonts/Jost-ExtraLightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}