@use "src/scss/config" as *;

.cmd {
    $root: &;

    &--wrap &__code {
        line-break: anywhere;
        white-space: inherit;
    }

    &--full-width &__command {
        width: 100%;

        @include media(">lg") {
            width: 100%;
        }
    }

    &__caption {
        margin-bottom: 1rem;
    }

    &__command {
        display: flex;
        align-items: center;
        border-radius: 5px;
        width: fit-content;
        padding: 0.5rem 1rem;
        margin-bottom: 1rem;
        min-width: 75%;
        width: 100%;

        @include media(">lg") {
            width: 90%;
        }
    }

    &__copy {
        border: none;
        font-size: 0.8em;
        cursor: pointer;
        margin-left: auto;
        padding: 0.5rem 1.5rem;

        &:hover {
            color: var(--primary);
        }
    }

    &__code {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 10;
    }

    &--light {
        #{$root}__command {
            background-color: var(--bg-command);

            @include media(">lg") {
                width: 90%;
            }
        }

        #{$root}__code {
            color: var(--paragraphs);
            font-size: 1.2rem;
        }

        #{$root}__copy {
            background-color: transparent;
            color: var(--icon-primary);
        }
    }

    &--dark {
        #{$root}__command {
            background-color: var(--bg-nav--console);
        }

        #{$root}__code {
            background-color: var(--bg-nav--console);
            color: var(--title-head);
            font-size: 1.2rem;
        }

        #{$root}__copy {
            background-color: transparent;
            color: var(--title-head);
        }
    }
}