@use "src/scss/config" as *;

.upload-sequence {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  padding: 0 2em;
  height: 150px;
  border: 1px solid var(--ui-base);
  border-radius: 14px;
  background-color: var(--bg-default);
  text-align: center;
  justify-content: center;
  transition: opacity 0.5s ease-out;

  input:focus+&--idle {
    border: 2px solid var(--outline);
    // background-color: var(--bg-container);
  }

  &--idle {
    border: 1px dashed var(--ui-base);
    cursor: pointer;
  }

  &--error {
    border: 1px dashed var(--ui-base);
  }

  &--fade {
    opacity: 0.5;
  }

  &__icon {
    width: 100%;
    height: 4em;
    fill: var(--ui-base);

    &--error {
      fill: var(--error);
    }
  }

  &__text {
    &--hint {
      color: var(--link-label);
    }

    &--small {
      font-size: 1.4rem;
    }
  }
}
