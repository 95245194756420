/** *********** GRID *********** **/

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1215px,
);

/** *********** TYPOGRAPHY *********** **/

// font types
$font-default: "Open Sans",
    Arial,
    Helvetica,
    sans-serif;
$font-headings: "Jost",
    Arial,
    Helvetica,
    sans-serif;

// sizes
$font-xxs: 1.2rem;
$font-xs: 1.4rem;
$font-s: 1.6rem;
$font-m: 1.8rem;
$font-l: 2.4rem;
$font-xl: 2.6rem;
$font-xxl: 2.8rem;

/** *********** COLORS *********** **/

// Basic
$white: #fff;
$black: #000;

// Gray
$gray-80: #3c3d3e;
$gray-20: #f4f3f3;

// Blue Gray
$blue-gray-20: #f0f4f8;

// Blue
$blue-100: #002849;
$blue-60: #1770c9;
$blue-20: #e7f4ff;

// Aqua
$aqua-100: #044e54;
$aqua-90: #0a6c74;
$aqua-70: #14919b;

// Orange
$orange-100: #d06d16;
$orange-80: #f07e19;
$orange-70: #f5943e;
$orange-50: #f8be8c;
$orange-20: #fbefe4;
$orange-10: #fcf7f3;

// Red
$red-80: #911111;
$red-60: #ba2525;
$red-50: #d64545;

/** *********** Z-INDEX *********** **/

$z-popup: 5;
$z-toast: 4;
$z-select: 5;
$z-menu: 2;
$z-nav: 1;
$z-hidden: -1;

/** *********** SIZES *********** **/

// Margins

// Paddings