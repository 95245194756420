@use "src/scss/config" as *;

.basic-info {
    width: 100%;
    justify-content: space-around;

    &__inst {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        color: var(--icon-inst);
    }

    &__seq {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        color: var(--icon-seq);
    }

    &__id-container {
        display: flex;
        align-items: flex-end;
    }

    &__copy-btn {
        margin-left: inherit !important;
    }
}
