@use "src/scss/config" as *;

.error {
    &-page {
        background: bottom 0 right 0 no-repeat;
        background-size: 450px;
        height: 60vh;

        @include media(">sm") {
            background: bottom 0 right 0 no-repeat;
        }

        @include media(">lg") {
            height: 70vh;
            background: top 0 center no-repeat;
        }
    }

    &-info {
        padding-top: 1vh;

        @include media(">md") {
            padding-top: 10vh;
        }
    }
}
