@use "src/scss/config" as *;

.upload-layer {
  position: relative;
  min-width: 460px;
  max-width: 100%;

  @include media(">lg") {
    min-width: 460px;
  }

  &__container {
    overflow: auto;
  }

  &__label {
    pointer-events: none;
    display: block;
  }

  &__input {
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    -moz-opacity: 0;
    opacity: 0;
    z-index: 2;
  }
}
