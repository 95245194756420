@use "src/scss/config" as *;

.tab-menu {
    $root: &;

    border-radius: 0.7rem;
    background: var(--bg-container);
    color: var(--paragraphs);
    box-shadow: var(--shadow-nav);
    font-size: 1.4rem;
    clear: both;

    &--vertical.tab-menu {
        #{$root} {
            &__list {
                min-width: 30%;
                padding: 2.3rem 1rem;
                border: 1px solid var(--border-light);
                border-radius: 0.7rem 0 0 0.7rem;

                @include media(">sm") {
                    padding: 2.3rem 2rem;
                }

                @include media(">md") {
                    min-width: 25%;
                    width: auto;
                }

                @include media(">xxl") {
                    padding: 2.3rem 2.5rem;
                    min-width: 20%;
                }
            }
        }
    }

    &--horizontal.tab-menu {
        #{$root} {
            &__list {
                padding: 1em 1.5em 0;

                @include media(">md") {
                    padding: 1em 4em 0;
                }
            }

            &__container {
                width: 100%;
            }
        }
    }

    &--transparent.tab-menu {
        background: transparent;

        #{$root} {
            &__list {
                background: transparent;
                padding: 0;
            }

            &__content {
                padding: 0;
            }
        }
    }

    &--button.tab-menu {
        background: transparent;

        #{$root} {
            &__list {
                background: transparent;
                padding: 0;
            }

            &__content {
                padding: 0;
            }

            &__item-text {
                border: 1px solid transparent;
                border-radius: 10px;
                padding: 1rem;

                &:hover {
                    border: 1px solid var(--border-btn);
                }
            }

            &__item {
                @include unify-parent("[data-selected]") {
                    border-color: transparent;

                    .tab-menu__item-text {
                        background-color: var(--tab-nav-color);
                        color: var(--tab-nav-text);
                    }
                }

                &:active {
                    background: none;
                }
            }
        }
    }

    & &__list {
        font-size: 1.6rem;
        border-radius: 0.7rem 0.7rem 0 0;
        border-bottom: 1px solid var(--border-light);
        background: var(--bg-container);
        text-align: left;
        padding: 2.3rem 2.5rem 0;

        @include media(">md") {
            padding: 1em 4em 4em;
        }
    }

    & &__indicator {
        margin-left: 0.5em;
        padding: 0.1em 0.6em;
        background-color: var(--bg-indicators);
        border-radius: 10px;
        display: inline-block;
    }

    & &__item {
        padding: 1.5rem 1rem 1.5rem 0;
        text-align: left;
        border: 1px solid transparent;
        border-radius: 10px 10px 0 0;
        text-decoration: none;

        @include unify-parent("[data-selected]") {
            border-bottom-color: var(--ui-accent);
        }

        @include media(">md") {
            padding: 1.5rem;
        }
    }

    &__container {
        padding: 0;

        @include media(">lg") {
            width: 95%;
        }
    }

    &__context {
        justify-content: space-around;
        flex-direction: column;
        padding: 1rem 0 3rem;

        @include media(">lg") {
            flex-direction: row;
        }
    }

    &__content {
        width: 100%;
        padding: 1em;

        &:nth-child(2) {
            padding: 1em;

            @include media(">xl") {
                padding: 1em 3em 1em 0;
            }
        }

        @include media(">lg") {
            min-width: 50%;
            padding: 1em 2em;
        }

        @include media(">xxl") {
            padding: 1em 3em 1em;
        }

        &--icon {
            align-items: center;

            svg {
                margin-right: 5px;
            }
        }

        .subtitle-2:first-child {
            margin-top: 0;
        }
    }
}
