@use "src/scss/config" as *;

.console-log {
    width: 100%;
    background-color: var(--bg-console);
    border-radius: 5px;
    clear: both;

    &__header {
        display: flex;
        justify-items: stretch;
        align-items: flex-end;
        border-radius: 5px 5px 0 0;
        background-color: var(--bg-nav--console);
    }

    &__tabs {
        flex: 1 1 50%;
        display: flex;
        align-items: flex-end;
    }

    &__tab {
        display: inline-block;
        padding: 1.5rem 3rem;
        background-color: var(--bg-console);
        border-radius: 20px 20px 0 0;
        color: var(--primary);
        font-size: 1.2em;
    }

    &__menu {
        flex: 1 1 50%;
        padding: 1.5rem;
        text-align: right;
    }

    &__download {
        font-size: 1.4em;
        color: var(--nav-links);
    }


    &>div>div>pre {
        margin: 0.5em 0;
        padding: 0.5em 0;
        border-bottom: 1px solid var(--bg-border);
        color: white;
        width: auto;
        min-width: 100%;
        // line-break: anywhere;
        // white-space: pre-wrap;
        // word-wrap: break-word;
    }
}
