@use "src/scss/config" as *;

.error-info {
    margin: 20px auto 50px;
    text-align: center;
    display: block;

    &__title {
        margin: 0.5em 0;
    }

    &__image {
        margin-left: auto;
        margin-right: auto;
    }
}