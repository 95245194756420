@use "src/scss/config" as *;

.top-bar {
    box-shadow: 0 3px 6px var(--shadow-nav);
    background: var(--top-bar);
    padding: 0 3%;
    min-height: 60px;

    &__container {
        display: flex;
        flex-direction: row;
        position: relative;
        align-items: center;
        justify-content: space-between;
        height: 55px;
    }

    &__link {
        display: inline-block;
    }

    &__logo {
        height: 45px;
        vertical-align: middle;
    }

    &__left,
    &__center,
    &__right {
        display: flex;
    }

    &__left {
        flex-grow: 1;
    }

    &__center {
        width: 0;

        @include media(">md") {
            flex-grow: 2;
        }
    }

    &__right {
        flex-grow: 1;
    }

    &__right {
        align-self: end;
        flex-direction: row;
        justify-content: end;
        align-items: end;

        @include media(">md") {
            align-self: auto;
        }
    }

    .logo--white {
        display: none;
    }

    .dark & {
        .logo--basic {
            display: none;
        }

        .logo--white {
            display: block;
        }
    }
}
