@use "src/scss/config" as *;

.video {
    border: none;
    outline: none;

    &--onboarding {
        width: 100%;
        height: 100%;

        @include media(">sm") {
            width: 470px;
            height: 300px;
        }

        @include media(">lg") {
            width: 640px;
            height: 400px;
        }

        @include media(">xxl") {
            width: 1024px;
            height: 600px;
        }
    }
}

.video-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
