.icon--healthy,
.icon--success {
    fill: var(--success);
}

.icon--warning {
    fill: var(--warning);
}

.icon--unhealthy,
.icon--error,
.icon--danger {
    fill: var(--error);
}

.icon--inst,
.icon--primary {
    fill: var(--icon-inst);
}

.icon--seq,
.icon--secondary {
    fill: var(--icon-seq);
}

.icon--rotating {
    animation: rotation 1s linear both;
    animation-iteration-count: infinite;
}

@keyframes rotation {
    100% {
        transform: rotate(360deg);
    }
}