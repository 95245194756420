@use "src/scss/config" as *;

.modal {
    $root: &;

    &--promo,
    &--occasional,
    &--onboarding {
        #{$root}__promocode {
            text-align: center;
            border: 1px solid var(--primary);
            border-radius: 5px;
            padding: 1rem;
            color: var(--primary);
        }

        #{$root}__content {
            padding: 2rem 2rem 2rem 3rem;
        }

        #{$root}__legal {
            text-align: center;
            margin-top: 2rem;
        }
    }

    &--promo,
    &--occasional {
        padding: 2rem;

        @include media(">lg") {
            padding: 0;
            min-width: 900px;
            flex-direction: row;
        }
    }

    &--onboarding {
        max-width: fit-content;
        min-width: max-content
    }
}
